
import {
  BackgroundImage, Title, Container,
  Text, Group, Badge, Center, Button, Image
} from '@mantine/core';
import image from "../assets/velar.jpg";
import { useLocation } from 'react-router-dom';
import { SetStateAction, useEffect, useState } from 'react';
import classes from './CarHero.module.css';
import axios from 'axios';
import { IconGasStation, IconGauge, IconManualGearbox, IconUsers, IconCoinEuro, IconCalendar, IconPhone, IconAt } from '@tabler/icons-react';
import { GetInTouch } from './Contact';
import '@mantine/carousel/styles.css';
import { Carousel } from '@mantine/carousel';
import { Loader } from './Loader';

interface Car {
  id: number;
  make: string;
  modele: string;
  year: number;
  images: Array<{ name: string; type: string; data: string }>;
}

export function CarHero() {
  const [selectedImage, setSelectedImage] = useState<any>([]);
  const [car, setCar] = useState<any>({ id: 9999 });
  const location = useLocation();
  const [cars, setCars] = useState<any>(null);
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  const isMobile = width <= 768;

  const handleClick = (image: any) => {
    setSelectedImage(image);
  };

  const mockdata = [
    { label: `${cars?.place} places`, icon: IconUsers },
    { label: `${cars?.kilo} km`, icon: IconGauge },
    { label: `Boite : ${cars?.transmission}`, icon: IconManualGearbox },
    { label: cars?.carburant, icon: IconGasStation },
    { label: `Garantie : ${cars?.garantie}`, icon: IconCoinEuro },
    { label: `Mise en circulation : ${cars?.year}`, icon: IconCalendar },
  ];

  const features = mockdata.map((feature, i) => (

    <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', margin: 5, alignContent: 'center', alignItems: 'center' }} key={feature.label}>
      <feature.icon size="1.5rem" className={classes.icon} stroke={1.5} />
      <Text size="lg">{feature.label}</Text>
    </div>
  ));

  function formatNumber(number: any) {
    // Convert number to string
    let numStr = number.toString();

    // Check if the number has more than 3 digits
    if (numStr.length > 3) {
        // Insert space at the desired position
        numStr = numStr.slice(0, numStr.length - 3) + ' ' + numStr.slice(numStr.length - 3);
    }

    // Return the formatted number
    return numStr;
}

//https://api.carmatchriviera.fr
//http://127.0.0.1:8000/

  useEffect(() => {
    if (car.id !== 9999) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://api.carmatchriviera.fr/get_car/${car.id}`);
          setCars(response.data);
          setSelectedImage(response.data.images[0]);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }

  }, [car]);

  useEffect(() => {
    if (location && location.state && location.state.carData) {
      setCar(location.state.carData)
    }
  }, [location])

  const slides = cars && cars?.images && cars.images.map((image: any, index: any) => (
    <Carousel.Slide key={index}>
      <Image style={{ borderRadius: 10 }} height={isMobile ? 'auto' : 350} width={450} onClick={() => handleClick(image)} src={`data:${image.type};base64,${image.data}`} />
    </Carousel.Slide>
  ));

  if(cars) {
    return (
      <>
        <BackgroundImage
          src={image}
          radius="sm"
          mb={100}
          style={{ minHeight: '100vh', paddingTop: 80 }}
        >{cars &&
          <Container style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }} size="lg">
            <div className={isMobile ? classes.carHeroWrapperMobile : classes.carHeroWrapper} style={{ width: '90vw', display: 'flex', marginTop: 50, flexDirection: 'column', }}>
  
              <div className={isMobile ? classes.topWrapperMobile : classes.topWrapper}  style={{ width: '100%', display: 'flex', padding: 5 }}>
  
                <div className={isMobile ? classes.topLeftWrapperMobile : classes.topLeftWrapper} style={{ height: '100%', display: 'flex', flexDirection: 'row', padding: 5, justifyContent: 'center' }}>
                  <div className={classes.carWrapper} style={{ textAlign: 'center' }}>
                    <div >
  
                      <Carousel loop withIndicators>
                        {slides}
                      </Carousel>
                    </div>
                    <Group justify="Center" mt="md">
              <Button component="a"
        href="tel:+330687215194" style={{backgroundColor:'#ba0c2f'}} className={classes.control}> <IconPhone stroke={2} size={15}/>&nbsp;&nbsp;06 87 21 51 94</Button>
              <Button component="a" href="mailto:contact@carmatchriviera.fr" style={{backgroundColor:'#e0e0e0e0', color:'#434242', alignItems:'center', lineHeight:1}} className={classes.control}>contact@carmatchriviera.fr</Button>
            </Group>
                  </div>
  
                </div>
  
                <div className={isMobile ? classes.topRightWrapperMobile : classes.topRightWrapper} style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: 5, alignItems: 'center', justifyContent: 'flex-start' }}>
                  <div style={{ width: '90%', height: 'auto', margin: 10 }}>
                    <Title style={{ letterSpacing: 1 }} order={3} className={classes.title} ta="left">
                      {cars.make} {cars.modele} {cars.config}
                    </Title>
  
                  </div>
  
                  <div style={{ width: '90%', height: 50, margin: '0px' }}>
                    <Title style={{ letterSpacing: 1 }} order={2} ta="left" mb="xl">
                      {formatNumber(cars.price)} €
                    </Title>
  
                  </div>
  
                  <div style={{ width: '100%', margin: 10, display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'flex-start', padding: 10 }}>
  
                    {features}
  
                  </div>
  
  
                </div>
  
              </div>
  
              <div className={isMobile ? classes.bottomWrapperMobile : classes.bottomWrapper} style={{ height: '50%', width: '80%', display: 'flex', padding: 5, alignItems: 'center', justifyContent: 'flex-start' }}>
                <div style={{ width: '100%', margin: 10 }}>
                  <Title style={{ letterSpacing: 1, color: '#fff' }} order={3} className={classes.title} ta="left" mb="sm" mt="xl">
                    Description :
                  </Title>
                  {cars.description}
                </div>
              </div>
  
            </div>
  
            <GetInTouch />
  
  
          </Container>
          }
  
        </BackgroundImage>
      </>
  
    );
  } else {
    return <Loader />
  }




}