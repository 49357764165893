import { useDisclosure } from '@mantine/hooks';
import { LoadingOverlay, Button, Group, Box } from '@mantine/core';

export function Loader() {

  // Note that position: relative is required
  return (
    <>
      <Box pos="relative">
        <LoadingOverlay
          visible={true}
          zIndex={1}
          overlayProps={{ radius: 'sm', blur: 2, bg:' #ffffff00'}}
          loaderProps={{ color: '#ba0c2f', type: 'bars' }}
        />
        <div style={{height:'100vh', width:'100vw', borderRadius:10}}>

        </div>
      </Box>
    </>
  );
}