import {
    TextInput,
    Title,
    Container,
    BackgroundImage,
} from '@mantine/core';

import { SetStateAction, useEffect, useState } from 'react';
import classes from './Services.module.css';
import image from "../assets/velar.jpg";
import FeaturesCard from './Card';

import axios from 'axios';
import { Loader } from './Loader';


interface CardProps {
    data: any;
}

function Card({ data }: CardProps) {
    return (
        <div style={{ width: 300, margin: 15 }} className={classes.carCard}>
            <FeaturesCard data={data} />
        </div>

    );
}

//https://api.carmatchriviera.fr
//http://127.0.0.1:8000/


export function Cars() {
    const [cars, setCars] = useState<any>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.carmatchriviera.fr/get_cars');
                setCars(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

if(cars && cars.length > 0 ){
    return (
        <>
            <BackgroundImage
                src={image}
                radius="sm"
                mb={100}
                style={{ minHeight: '100vh', paddingTop: 80 }}
            >
                <Container size="xl">
                    <Title style={{ letterSpacing: 3 }} order={2} className={classes.title} ta="center" mb="xl">
                        NOS OCCASIONS
                    </Title>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', }} >
                        <div className={classes.filterCars} style={{ backgroundColor: '#191919a8', minWidth: 250, height: 'fit-content', marginTop: 15, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', borderRadius: 10, padding: '0px 15px 15px 15px' }}>
                            <Title style={{ width: '80%', alignContent: 'center', letterSpacing: 2, verticalAlign: 'center', marginBottom: 50, marginTop: 10 }} order={3}>Nos occasions</Title>
                            <TextInput
                                label="Marque"
                                placeholder="..."

                                classNames={{ input: classes.input, label: classes.inputLabel }}
                            />
                            <TextInput
                                label="Modele"
                                placeholder="..."

                                classNames={{ input: classes.input, label: classes.inputLabel }}
                            />
                            <TextInput
                                label="Carburant"
                                placeholder="..."

                                classNames={{ input: classes.input, label: classes.inputLabel }}
                            />

                            <TextInput
                                label="Transmission"
                                placeholder="..."

                                classNames={{ input: classes.input, label: classes.inputLabel }}
                            />
                            <TextInput
                                label="Kilométrage max"
                                placeholder="km"

                                classNames={{ input: classes.input, label: classes.inputLabel }}
                            />
                            <TextInput
                                label="Année min"
                                placeholder=""

                                classNames={{ input: classes.input, label: classes.inputLabel }}
                            />
                            <TextInput
                                label="Année max"
                                placeholder="..."

                                classNames={{ input: classes.input, label: classes.inputLabel }}
                            />
                        </div>
                        {cars &&
                            <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                                {cars.map((car: any, index: any) => (
                                    <Card key={index} data={car} />
                                ))}
                            </div> 
                        }

                    </div>

                </Container>
            </BackgroundImage>
        </>

    );
} else {
return <Loader />
}

}