import { Container, Title, Text, Button } from '@mantine/core';
import classes from './about.module.css';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

export function About() {

    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/services`;
        navigate(path);
    }

    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
    const isMobile = width <= 768;

    return (
        <div className={isMobile ? classes.rootMobile: classes.root}>
            <Container size="xl" >
                <div className={isMobile ? classes.innerMobile: classes.inner}>
                    <div className={isMobile ? classes.contentMobile: classes.content}>
                    <br /><br />
                        <Title className={isMobile ? classes.titleMobile: classes.title}>
                            QUI SOMMES NOUS ?
                        </Title>

                        <Text  className={isMobile ? classes.descriptionMobile: classes.description} mt={30}>
                            Specialisé dans le domaine de l’ automobile carmatch riviera met son savoir faire et son experience a votre service avec son dirigeant qui a plus 20 ans de connaissance dans l’ automobile, nous mettrons tout en ouvre pour vous accompagner dans votre projet auto.<br /><br /> Soucieux de la qualite de nos services c’est avec la plus grande rigueur et un cahier
                            des charges strict que nous selectionnons les vehicules mis a la vente et les demandes
                            personnalisees.<br /><br /> CARMATCH RIVIERA est totalement indépendant des constructeurs automobiles. de ce fait, nous pouvons vous conseiller en toute objectivité et vous proposer le véhicule répondant à toutes vos attentes.
                        </Text>

                        <Button
                            className={classes.control}
                            mt={40}
                            onClick={routeChange}
                        >
                            Accéder à nos différents services
                        </Button>
                    </div>
                </div>
            </Container>
        </div>
    );
}