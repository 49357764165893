import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import axios from 'axios';
import {
  Text,
  Title,
  SimpleGrid,
  TextInput,
  Textarea,
  Button,
  Group,
  ActionIcon,
} from '@mantine/core';
import { IconBrandTwitter, IconBrandYoutube, IconBrandInstagram, IconBrandFacebook } from '@tabler/icons-react';
import { ContactIconsList } from './ContactIcons';
import classes from './SearchPerso.module.css';

const social = [IconBrandFacebook, IconBrandInstagram];

export function SearchPerso() {
  const [Marque, setMarque] = useState<string>('');
  const [Modele, setModele] = useState<string>('');
  const [Configuration, setConfiguration] = useState<string>('');
  const [AnnéeMin, setAnnéeMin] = useState<string>('');
  const [AnnéeMax, setAnnéeMax] = useState<string>('');
  const [Autres, setAutres] = useState<string>('');
  const [Transmission, setTransmission] = useState<string>('');
  const [Kilométrage, setKilométrage] = useState<string>('');
  const [Place, setPlace] = useState<string>('');
  const [Prix, setPrix] = useState<string>('');
  const [Garantie, setGarantie] = useState<string>('');
  const [Carburant, setCarburant] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [numero, setNumero] = useState<string>('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  const isMobile = width <= 768;

  useEffect(()=>{
    const sentAlready = localStorage.getItem('searchRequestSent');
  },[loading])

  const handleMarqueChange = (e: any) => {
    setMarque(e.target.value);
  };

  const handleModeleChange = (e: any) => {
    setModele(e.target.value);
  };

  const handleConfigChange = (e: any) => {
    setConfiguration(e.target.value);
  };

  const handleAnneeMinChange = (e: any) => {
    setAnnéeMin(e.target.value);
  };

  const handleAnneeMaxChange = (e: any) => {
    setAnnéeMax(e.target.value);
  };

  const handleTransChange = (e: any) => {
    setTransmission(e.target.value);
  };

  const handleKiloChange = (e: any) => {
    setKilométrage(e.target.value);
  };

  const handlePlaceChange = (e: any) => {
    setPlace(e.target.value);
  };

  const handleGarantieChange = (e: any) => {
    setGarantie(e.target.value);
  };

  const handlePrixChange = (e: any) => {
    setPrix(e.target.value);
  };

  const handleCarbuChange = (e: any) => {
    setCarburant(e.target.value);
  };

  const handleAutresChange = (e: any) => {
    setAutres(e.target.value);
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleNumChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNumero(e.target.value);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  //https://api.carmatchriviera.fr
//http://127.0.0.1:8000/

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setLoading(true)
    setSent(false)
    const sentAlready = localStorage.getItem('searchRequestSent');
    if (email && name && Prix && Marque && AnnéeMin) {
      setError(false)
      const formData = new FormData();
      formData.append('title', Marque);
      formData.append('', Modele);
      formData.append('Argument', Configuration);
      formData.append('AnneeMin', AnnéeMin);
      formData.append('AnneeMax', AnnéeMax);
      formData.append('Carburant', Carburant);
      formData.append('description', Autres);
      formData.append('Transmission', Transmission);
      formData.append('Kilo', Kilométrage);
      formData.append('Place', Place);
      formData.append('Prix', Prix);
      formData.append('Garantie', Garantie);
      formData.append('email', email);
      formData.append('name', name);
      formData.append('num', numero);
      try {
        const response = await axios.post('https://api.carmatchriviera.fr/searchRequest', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setLoading(false)
        setSent(true)
        localStorage.setItem('searchRequestSent', JSON.stringify(true));
        console.log(response.data); // handle response from backend
      } catch (error) {
        setLoading(false)
        console.error('Error uploading data:', error);
      }
    } else {
      setLoading(false)
      setError(!error);
    }

  };

  const icons = social.map((Icon, index) => (
    <ActionIcon key={index} size={28} className={classes.social} variant="transparent">
      <Icon size="1.4rem" stroke={1.5} />
    </ActionIcon>
  ));

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center", paddingTop: 130, marginBottom: 50 }}>
      <div className={isMobile ? classes.wrapperMobile : classes.wrapper}>
        <SimpleGrid cols={{ base: 1, sm: 2 }} spacing={50}>
          <div>
            <Title className={classes.title}>Recherche personnalisé</Title><br /> <br />
            <Text className={isMobile ? classes.descriptionMobile : classes.description} mt="sm" mb={30}>
              Ne perdez plus votre temps.<br /><br />  Notre service de recherche personnalisée de véhicules vous permet de gagner du temps précieux en trouvant rapidement le véhicule idéal qui répond à vos besoins, votre budget et vos préférences spécifiques.<br /> <br />  Avec notre expertise et notre réseau étendu, nous vous offrons une expérience de recherche sans stress, vous permettant de vous concentrer sur ce qui compte vraiment : profiter de votre nouvelle voiture.
            </Text>
          </div>
          <div className={classes.form}>

            <div className={isMobile ? classes.formCarMobile : classes.formCar}  style={{ width: "100%", marginBottom:30, height: 'auto', display: 'flex' }}>
              <div className={isMobile ? classes.formCarColumnMobile : classes.formCarColumn} style={{ paddingRight:15, height: 'auto', }}>
                <TextInput
                  label="Marque"
                  placeholder=""
                  required
                  value={Marque} onChange={handleMarqueChange}
                  classNames={{ input: classes.input, label: classes.inputLabel }}
                />
                <TextInput
                  label="Transmission"
                  placeholder=""
                  required
                  value={Transmission} onChange={handleTransChange}
                  classNames={{ input: classes.input, label: classes.inputLabel }}
                />
                <TextInput
                  label="Kilométrage max"
                  placeholder=""
                  required
                  value={Kilométrage} onChange={handleKiloChange}
                  classNames={{ input: classes.input, label: classes.inputLabel }}
                />
                <TextInput
                  label="Année min"
                  placeholder=""
                  required
                  value={AnnéeMin} onChange={handleAnneeMinChange}
                  classNames={{ input: classes.input, label: classes.inputLabel }}
                />
              </div>
              <div className={isMobile ? classes.formCarColumnMobile : classes.formCarColumn} style={{ paddingRight:15, height: 'auto', }}>
                <TextInput
                  label="Modele"
                  placeholder=""
                  required
                  value={Modele} onChange={handleModeleChange}
                  classNames={{ input: classes.input, label: classes.inputLabel }}
                />
                <TextInput
                  label="Budget"
                  placeholder=""
                  required
                  value={Prix} onChange={handlePrixChange}
                  classNames={{ input: classes.input, label: classes.inputLabel }}
                />
                <TextInput
                  label="Carburant"
                  placeholder=""
                  required
                  value={Carburant} onChange={handleCarbuChange}
                  classNames={{ input: classes.input, label: classes.inputLabel }}
                />
                <TextInput
                  label="Année max"
                  placeholder=""
                  required
                  value={AnnéeMax} onChange={handleAnneeMaxChange}
                  classNames={{ input: classes.input, label: classes.inputLabel }}
                />
              </div>
            </div>

            <TextInput
              label="Email"
              placeholder=""
              required
              value={email} onChange={handleEmailChange}
              classNames={{ input: classes.input, label: classes.inputLabel }}
            />
            <TextInput
              label="Nom"
              placeholder=""
              required
              mt="md"
              value={name} onChange={handleNameChange}
              classNames={{ input: classes.input, label: classes.inputLabel }}
            />
            <TextInput
              label="Numéro de téléphone"
              placeholder=""
              required
              mt="md"
              type='numeric'
              value={numero} onChange={handleNumChange}
              classNames={{ input: classes.input, label: classes.inputLabel }}
            />

            {
              error && <Text style={{ color: 'red', fontSize: 14 }} >
                Veuillez remplir les champs obligatoires (*)
              </Text>
            }

            <Group justify="flex-end" mt="md">
              <Button disabled={loading || sent} onClick={handleSubmit} className={ sent ? classes.controlGreen : classes.control}> {loading ? 'Demande en cours...' : sent ? 'Demande prise en compte' : 'Envoyer'}</Button>
            </Group>
          </div>
        </SimpleGrid>
      </div>
    </div>

  );
}