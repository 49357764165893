import React from 'react';
import logo from './logo.svg'
import './App.css';
import { Routes, Route } from "react-router-dom"

import { Nav } from './components/Nav';
import {Services} from './components/Services';
import {GetInTouch} from './components/Contact';
import Orders from './components/Orders';
import Cart from './components/Cart';
import Home from './components/Home';
import Oops from './components/Oops';
import { About } from './components/About';
import { Cars } from './components/CarFeature';
import { AuthenticationTitle } from './components/Authent';
import { CarHero } from './components/CarHero';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <div>
      <Routes>
        <Route element={<Nav />} path={"/"} >
          <Route element={<Home />} index  />
          <Route element={<Services />} path="services"  />
          <Route element={ <GetInTouch />} path="contact"  />
          <Route element={<About />} path="apropos"  />
          <Route element={<Cars />} path="occasions"  />
          <Route element={<CarHero />} path="detail"  />
          <Route element={<AuthenticationTitle />} path="admin"  />
          <Route path="*" element={<Oops />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
