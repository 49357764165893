import React, { useRef, useState, useEffect } from 'react';
import { Text, Group, Button, rem, useMantineTheme, Image, Badge, ActionIcon } from '@mantine/core'; // Import Badge and ActionIcon from Mantine
import { Dropzone, IMAGE_MIME_TYPE, MIME_TYPES } from '@mantine/dropzone';
import { IconCloudUpload, IconX, IconDownload } from '@tabler/icons-react';
import classes from './Dropzone.module.css';

export function DropzoneButton() {
  const theme = useMantineTheme();
  const openRef = useRef<() => void>(null);

  return (
    <div className={classes.wrapper}>
      <Dropzone
        disabled
        openRef={openRef}
        onDrop={()=>console.log()} 
        className={classes.dropzone}
        radius="md"
        accept={IMAGE_MIME_TYPE}
        maxSize={30 * 1024 ** 2}
      >
        <div style={{ pointerEvents: 'none' }}>
          <Group justify="center">
          
          <IconCloudUpload
                style={{ width: 100, height: 100 }}
                color={'grey'}
                stroke={1.5}
              />

          </Group>
          <Text ta="center" fz="sm" mt="xs" c="dimmed">
            Sélectionne les photos pour le véhicule à l'aide du bouton ci-dessous
          </Text>
        </div>
      </Dropzone>
    </div>
  );
}
