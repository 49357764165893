import { BackgroundImage, Card, Image, Text, Group, Badge, Center, Button } from '@mantine/core';
import { IconGasStation, IconGauge, IconManualGearbox, IconUsers } from '@tabler/icons-react';
import classes from './FeaturesCard.module.css';
import image from "../assets/Audi_Q5.jpg"
import { useNavigate } from 'react-router-dom';

export const FeaturesCard = ({data} : any) => {

  const mockdata = [
    { label:`${data.place} places` , icon: IconUsers },
    { label:`${data.kilo} km`, icon: IconGauge },
    { label:`Boite ${data.transmission}`, icon: IconManualGearbox },
    { label: data.carburant, icon: IconGasStation },
  ];

  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `/detail`; 
    navigate(path, {state:{carData:data}});
  }

  function formatNumber(number: any) {
    // Convert number to string
    let numStr = number.toString();

    // Check if the number has more than 3 digits
    if (numStr.length > 3) {
        // Insert space at the desired position
        numStr = numStr.slice(0, numStr.length - 3) + ' ' + numStr.slice(numStr.length - 3);
    }

    // Return the formatted number
    return numStr;
}


  const features = mockdata.map((feature,i) => (
    
    <Center key={feature.label}>
      <feature.icon size="1.05rem" className={classes.icon} stroke={1.5} />
      <Text size="xs">{feature.label}</Text>
    </Center>
  ));

  return (
    <Card radius={"md"} className={classes.card}>
      <BackgroundImage
      src={`data:${data.images[0].type};base64,${data.images[0].data}`}
        style={{padding:0, margin:0}}
      >
        <Card.Section className={classes.imageSection}>
          <Image style={{ opacity: 0 }} w="auto"
            fit="contain" src={image} alt="Tesla Model S" height={250} />
        </Card.Section>
      </BackgroundImage>


      <Group justify="space-between" mt="md">
        <div>
          <Text fw={500}>{data.make} - {data.modele}</Text>
          <Text fz="xs" c="dimmed">
          {data.config} - Année {data.year}
          </Text>
        </div>
        <br/>
        <Badge color='#ffffff' variant="outline">Garantie : {data.garantie}</Badge>
      </Group>

      <Card.Section style={{ borderTop: "1px solid #4d4f6683" }} className={classes.section} mt="md">
        <Text fz="sm" c="dimmed" className={classes.label}>
          Configuration
        </Text>

        <Group gap={8} mb={-8}>
          {features}
        </Group>
      </Card.Section>

      <Card.Section className={classes.section}>
        <Group gap={30}>
          <div style={{display:"flex", alignItems:'baseline'}}>
            <Text fz="xl" fw={700} style={{ lineHeight: 1 }}>
              {formatNumber(data.price)} €
            </Text>
          </div>

          <Button radius="sm" onClick={routeChange} style={{ flex: 1 ,backgroundColor:'', color: "#2b2a28" }}>
            Plus de détails
          </Button>
        </Group>
      </Card.Section>
    </Card>
  );
}

export default FeaturesCard;