import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import axios from 'axios';
import {
  Text,
  Title,
  SimpleGrid,
  TextInput,
  Textarea,
  Button,
  Group,
  ActionIcon,
} from '@mantine/core';
import { IconBrandTwitter, IconBrandYoutube, IconBrandInstagram, IconBrandFacebook } from '@tabler/icons-react';
import { ContactIconsList } from './ContactIcons';
import classes from './GetInTouch.module.css';

const social = [IconBrandFacebook, IconBrandInstagram];

export function GetInTouch() {
  const [description, setDescription] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [numero, setNumero] = useState<string>('');
  const [error, setError] = useState(false);
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  const isMobile = width <= 768;

  const handleDescriptionChange = (e: any) => {
    setDescription(e.target.value);
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleNumChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNumero(e.target.value);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    //https://api.carmatchriviera.fr
//http://127.0.0.1:8000/

    if(email && description) {
      setError(false)
      const formData = new FormData();
      formData.append('description', description);
      formData.append('email', email);
      formData.append('name', name);
      formData.append('num', numero);
      try {
        const response = await axios.post('https://api.carmatchriviera.fr/email', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(response.data); // handle response from backend
      } catch (error) {
        console.error('Error uploading data:', error);
      }
    } else {
      setError(!error);
    }
    
  };



  const icons = social.map((Icon, index) => (
    <ActionIcon component='a' href={index === 0 ? 'https://www.facebook.com/groups/2248338119/user/61556256103057/?locale=fr_FR' : "https://www.instagram.com/carmatch_riviera/"} key={index} size={28} className={classes.social} variant="transparent">
      <Icon size="1.4rem" stroke={1.5} />
    </ActionIcon>
  ));

  return (
    <div style={{width:"100%", display:"flex", justifyContent:"center", paddingTop:130, marginBottom:50}}>
    <div className={isMobile ? classes.wrapperMobile : classes.wrapper}>
      <SimpleGrid cols={{ base: 1, sm: 2 }} spacing={50}>
        <div>
          <Title className={classes.title}>Nous contacter</Title>
          <Text className={isMobile ? classes.descriptionMobile : classes.description} mt="sm" mb={30}>
            Laissez nous un message, nous vous répondrons dans les plus bref délai.
          </Text>

          <ContactIconsList />
          <Text style={{marginLeft:40}}>
          Lun. à Ven. : 9h - 18h <br/>
          Samedi : 9h - 12h <br/>
          Dimanche : Fermé 
          </Text>

          <Group mt="xl">{icons}</Group>
        </div>
        <div className={classes.form}>
          <TextInput
            label="Email"
            placeholder="votre@email.com"
            required
            value={email} onChange={handleEmailChange}
            classNames={{ input: classes.input, label: classes.inputLabel }}
          />
          <TextInput
            label="Nom"
            placeholder="..."
            mt="md"
            value={name} onChange={handleNameChange}
            classNames={{ input: classes.input, label: classes.inputLabel }}
          />
          <TextInput
            label="Numéro de téléphone"
            placeholder="..."
            mt="md"
            type='numeric'
            value={numero} onChange={handleNumChange}
            classNames={{ input: classes.input, label: classes.inputLabel }}
          />
          <Textarea
            required
            label="Votre message"
            placeholder="..."
            minRows={4}
            mt="md"
            value={description} onChange={handleDescriptionChange}
            classNames={{ input: classes.input, label: classes.inputLabel }}
          />

          {
            error && <Text style={{color:'red', fontSize:14}} >
              Veuillez remplir les champs obligatoires (*)
            </Text>
          }

          <Group justify="flex-end" mt="md">
            <Button style={{backgroundColor:'#ba0c2f'}} onClick={handleSubmit} className={classes.control}>Envoyer</Button>
          </Group>
        </div>
      </SimpleGrid>
    </div>
    </div> 

  );
}