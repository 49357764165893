import { Container, Title, Text, Button } from '@mantine/core';
import classes from './HeroImageRight.module.css';
import { useNavigate } from 'react-router-dom';
import { Console } from 'console';
import React, { useState, ChangeEvent, FormEvent, useEffect, useRef } from 'react';

export function HeroImageRight() {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  const isMobile = width <= 768;

  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `/services`; 
    navigate(path);
  }

  return (
    <div className={isMobile ? classes.rootMobile : classes.root}>
      <Container size="xl" >
        <div className={isMobile ? classes.innerMobile : classes.inner}>
          <div className={isMobile ? classes.contentMobile : classes.content}>
            <Title className={isMobile ? classes.titleMobile : classes.title}>
            CARMATCH RIVIERA
            </Title>

            <Text className={isMobile ? classes.description : classes.descriptionMobile} mt={30}>
              Professionnel de l’automobile vous propose une selection de
              vehicules toutes marques neufs et occasions selectionnees avec la plus grande
              rigueur.<br/><br/>
              Nous sommes a votre disposition pour vous accompagne dans la realisation de votre
              projet.
            </Text>

            <Button
              className={classes.control}
              mt={40}
              onClick={routeChange}
            >
              Nous découvrir
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}