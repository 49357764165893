import React, { useState, ChangeEvent, FormEvent, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Text,
  Title,
  SimpleGrid,
  TextInput,
  Textarea,
  Button,
  Group,
  ActionIcon,
  Image
} from '@mantine/core';
import { IconBrandTwitter, IconBrandYoutube, IconBrandInstagram, IconBrandFacebook, IconX } from '@tabler/icons-react';
import { ContactIconsList } from './ContactIcons';
import classes from './CarForm.module.css';
import { DropzoneButton } from './Dropzone'

const social = [IconBrandFacebook, IconBrandInstagram];

export function CarForm({ setCarToUpdate, setShowModal, data, mode, setMode, carToEdit }: any) {
  const [Marque, setMarque] = useState<string>('');
  const [Modele, setModele] = useState<string>('');
  const [Configuration, setConfiguration] = useState<string>('');
  const [Année, setAnnée] = useState<string>('');
  const [Autres, setAutres] = useState<string>('');
  const [Transmission, setTransmission] = useState<string>('');
  const [Kilométrage, setKilométrage] = useState<string>('');
  const [Place, setPlace] = useState<string>('');
  const [Prix, setPrix] = useState<string>('');
  const [Garantie, setGarantie] = useState<string>('');
  const [Carburant, setCarburant] = useState<string>('');
  const [Images, setImages] = useState<any>([]);
  const [error, setError] = useState(false);
  const [uploadedImages, setUploadedImages] = useState<any>([]); // State to hold uploaded image URLs
  const [uploadedCount, setUploadedCount] = useState(0); // State to hold the number of uploaded images
  const [loading, setLoading] = useState(false);
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  const isMobile = width <= 768;

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (carToEdit) {
      setMarque(carToEdit.make);
      setModele(carToEdit.config);
      setConfiguration(carToEdit.config);
      setAnnée(carToEdit.year);
      setTransmission(carToEdit.transmission);
      setKilométrage(carToEdit.kilo);
      setPlace(carToEdit.place);
      setGarantie(carToEdit.garantie);
      setPrix(carToEdit.price);
      setCarburant(carToEdit.carburant);
      setAutres(carToEdit.model)
      setImages(carToEdit.images);
    }
  }, [carToEdit])

  const handleMarqueChange = (e: any) => {
    setMarque(e.target.value);
  };

  const handleModeleChange = (e: any) => {
    setModele(e.target.value);
  };

  const handleConfigChange = (e: any) => {
    setConfiguration(e.target.value);
  };

  const handleAnneeChange = (e: any) => {
    setAnnée(e.target.value);
  };

  const handleTransChange = (e: any) => {
    setTransmission(e.target.value);
  };

  const handleKiloChange = (e: any) => {
    setKilométrage(e.target.value);
  };

  const handlePlaceChange = (e: any) => {
    setPlace(e.target.value);
  };

  const handleGarantieChange = (e: any) => {
    setGarantie(e.target.value);
  };

  const handlePrixChange = (e: any) => {
    setPrix(e.target.value);
  };

  const handleCarbuChange = (e: any) => {
    setCarburant(e.target.value);
  };

  const handleAutresChange = (e: any) => {
    setAutres(e.target.value);
  };





  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedImages = Array.from(e.target.files);

      const imagesToShow = selectedImages.slice(0, 12 - uploadedCount).map(file => URL.createObjectURL(file)); // Get the file paths
      setUploadedImages((prevImages: any) => [...prevImages, ...imagesToShow]);

      setImages(selectedImages); // Replace existing images with newly selected ones

    }
  };

  const handleDelete = (index: number) => {
    const newImages = [...uploadedImages];
    newImages.splice(index, 1); // Remove the image at the specified index
    setUploadedImages(newImages);
    setUploadedCount(prevCount => prevCount - 1); // Decrement uploaded count
  };

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setLoading(true)

    if(mode === 'add' && Images === null){
      setError(true);
      setLoading(false)
      return;
    }
    const formData = new FormData();
    formData.append('title', Marque);
    formData.append('modele', Modele);
    formData.append('Argument', Configuration);
    formData.append('Annee', Année);
    formData.append('Carburant', Carburant);
    formData.append('description', Autres);
    formData.append('Transmission', Transmission);
    formData.append('Kilo', Kilométrage);
    formData.append('Place', Place);
    formData.append('Prix', Prix);
    formData.append('Garantie', Garantie);
    if(Images){
      Images.forEach((image: any) => {
        formData.append('images[]', image);
      });
    }

    //https://api.carmatchriviera.fr
//http://127.0.0.1:8000/

if(mode === 'add'){
  try {
    const response = await axios.post('https://api.carmatchriviera.fr/upload', formData, {
      headers: {
      },
    });
    console.log(response.data);
    setLoading(false) // handle response from backend
    setShowModal(false) 
    setCarToUpdate(null)
  } catch (error) {
    setLoading(false)
    setShowModal(false) 
    setCarToUpdate(null)
    console.error('Error uploading data:', error);
  }
}

if(mode === 'edit'){
  console.log(data);
  try {
    const response = await axios.post(`https://api.carmatchriviera.fr/edit/${data}`, formData, {
      headers: {
      },
    });
    console.log(response.data);
    setLoading(false) // handle response from backend
    setShowModal(false) 
    setCarToUpdate(null)
    setMode('add')
  } catch (error) {
    setLoading(false)
    setShowModal(false) 
    setCarToUpdate(null)
    setMode('add')
    console.error('Error editing data:', error);
  }
}


  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: 50 }}>
        <div className={isMobile ? classes.wrapperMobile : classes.wrapper}>
          <SimpleGrid cols={{ base: 1, sm: 2 }} spacing={50}>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              <DropzoneButton />
              <div onClick={handleButtonClick} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', cursor: 'pointer' }}>
                <label className={classes.inputFileImage} htmlFor='filef'>Ajouter des photos</label>
                <input
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  type="file"
                  onChange={handleImageChange}
                  multiple />
              </div>
              <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '50px' }}>
                {uploadedImages.map((imageUrl: any, index: any) => (
                  <div key={index} style={{ position: 'relative', marginRight: '10px', marginBottom: '10px' }}>
                    <Image src={imageUrl} alt={`Uploaded Image ${index}`} width={60} height={60} />
                    <ActionIcon
                      variant="filled"
                      color="red"
                      radius="xl"
                      style={{ position: 'absolute', top: '-8px', right: '-8px' }}
                      onClick={() => handleDelete(index)}
                    >
                      <IconX />
                    </ActionIcon>
                  </div>
                ))}
              </div>

            </div>
            <div className={classes.form}>

              <div className={isMobile ? classes.formCarMobile : classes.formCar} style={{ width: "100%", marginBottom: 30, height: 'auto', display: 'flex', }}>
                <div className={isMobile ? classes.formCarColumnMobile : classes.formCarColumn} style={{ paddingRight: 15, height: 'auto', }}>
                  <TextInput
                    label="Marque"
                    placeholder=""
                    value={Marque} onChange={handleMarqueChange}
                    classNames={{ input: classes.input, label: classes.inputLabel }}
                  />
                  <TextInput
                    label="Modele"
                    placeholder=""
                    value={Modele} onChange={handleModeleChange}
                    classNames={{ input: classes.input, label: classes.inputLabel }}
                  />
                  <TextInput
                    label="Configuration"
                    placeholder=""
                    value={Configuration} onChange={handleConfigChange}
                    classNames={{ input: classes.input, label: classes.inputLabel }}
                  />
                  <TextInput
                    label="Année"
                    placeholder=""
                    value={Année} onChange={handleAnneeChange}
                    classNames={{ input: classes.input, label: classes.inputLabel }}
                  />
                  <TextInput
                    label="Carburant"
                    placeholder=""
                    value={Carburant} onChange={handleCarbuChange}
                    classNames={{ input: classes.input, label: classes.inputLabel }}
                  />
                </div>
                <div className={classes.formCarColumn} style={{ paddingRight: 15, height: 'auto', }}>
                  <TextInput
                    label="Transmission"
                    placeholder=""
                    value={Transmission} onChange={handleTransChange}
                    classNames={{ input: classes.input, label: classes.inputLabel }}
                  />
                  <TextInput
                    label="Kilométrage"
                    placeholder=""
                    value={Kilométrage} onChange={handleKiloChange}
                    classNames={{ input: classes.input, label: classes.inputLabel }}
                  />
                  <TextInput
                    label="Place"
                    placeholder=""
                    value={Place}
                    onChange={handlePlaceChange}
                    classNames={{ input: classes.input, label: classes.inputLabel }}
                  />
                  <TextInput
                    label="Prix"
                    placeholder=""
                    value={Prix} onChange={handlePrixChange}
                    classNames={{ input: classes.input, label: classes.inputLabel }}
                  />
                  <TextInput
                    label="Garantie"
                    placeholder=""
                    value={Garantie} onChange={handleGarantieChange}
                    classNames={{ input: classes.input, label: classes.inputLabel }}
                  />
                </div>


              </div>
              <Textarea
                    label="Description"
                    placeholder=""
                    value={Autres} onChange={handleAutresChange}
                    classNames={{ input: classes.inputDesc, label: classes.inputLabel }}
                  />
              {
                error && <Text style={{ color: 'red', fontSize: 14 }} >
                  Veuillez remplir les champs
                </Text>
              }
              <Group justify="flex-end" mt="md">
                {
                  mode === 'add' ?                 <Button disabled={loading} type="submit" className={classes.control}>{loading ? "Ajout en cours..." : "Ajouter" }</Button>:  <Button disabled={loading} type="submit" className={classes.control}>{loading ? "Modification en cours..." : "Modifier" }</Button>
                }

              </Group>
            </div>
          </SimpleGrid>
        </div>
        <ActionIcon
          variant="filled"
          color="#bebebea8"
          radius="lg"
          size={20}
          style={{ top: '-5px', right: '10px', width: 10, height: 10 }}
          onClick={() => { setShowModal(false); setCarToUpdate(null); setMode('add') }}
        >
          <IconX size={10} />
        </ActionIcon>
      </div>
    </form>
  );
}