import {
  Badge,
  Group,
  Title,
  Text,
  Card,
  SimpleGrid,
  Container,
  rem,
  useMantineTheme,
  BackgroundImage,
  List,
  ThemeIcon
} from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { IconCoinEuro, IconUser, IconFilePencil } from '@tabler/icons-react';
import classes from './Services.module.css';
import CarSaleSection from './CarSaleSection';
import image from "../assets/velar.jpg";
import { SearchPerso } from './SearchPerso';
import {useLocation} from 'react-router-dom';

const mockdata = [
  {
    title: 'Achat / vente',
    description:
      '',
    icon: IconCoinEuro,
    items: [
       ' Depot Vente', ' Accessoires',' Achat Neuf Et Occasions',
    ]
  },
  {
    title: 'Administratifs',
    description:
      '',
    icon: IconFilePencil,
    items: [
      ' Financement', ' Reprise', ' Carte Grise',
    ]
  },
  {
    title: 'Conseils',
    description:
      '',
    icon: IconUser,
    items: [
       ' Conseils', ' Garanties',' Preparation Esthetique',
    ]
  },
];

export function Services() {
  const theme = useMantineTheme();

  const features = mockdata.map((feature) => (
    <Card key={feature.title} shadow="md" radius="md" className={classes.card} padding="xl">
      <ThemeIcon gradient={{ from: 'gray', to: '#000', deg: 156 }} size={'xl'} variant="gradient" radius="md">
        <feature.icon style={{ width: '70%', height: '70%', color: "white" }} stroke={1.5} />
      </ThemeIcon>
      <Text fz="lg" fw={700} style={{ color: 'white' }} className={classes.cardTitle} mt="md">
        {feature.title}
      </Text>

      <List
        mt={30}
        spacing="sm"
        size="sm"
      >
        {feature.items.map(item => (
          <>
            <List.Item>
              <b>{item}</b>
            </List.Item>
          </>

        ))}

      </List>
      <Text mt={20} style={{ color: 'white' }} >
        {feature.description}
      </Text>
    </Card>
  ));
  
  return (
    <>

      <Container style={{paddingBottom:50}} size="lg" py="xl">

        <Title order={2} className={classes.title} ta="center" mt="sm">
          DES SERVICES SUR MESURES
        </Title>

        <SimpleGrid cols={{ base: 1, md: 3 }} spacing="xl" mt={50}>
          {features}
        </SimpleGrid>

        <SearchPerso/>
      </Container>
      {/* <BackgroundImage
        src={image}
        radius="sm"
      >
        <CarSaleSection />
      </BackgroundImage> */}
    </>

  );
}