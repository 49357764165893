import { useEffect, useState } from 'react';
import { Center, Tooltip, UnstyledButton, Stack, rem } from '@mantine/core';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    IconHome2,
    IconMail,
    IconDeviceDesktopAnalytics,
    IconFingerprint,
    IconCalendarStats,
    IconUser,
    IconSettings,
    IconLogout,
    IconSwitchHorizontal,
} from '@tabler/icons-react';
import logo from "../assets/logo_makki.png"
import { IoCarSport } from "react-icons/io5";
import { FaHandHoldingDollar } from "react-icons/fa6";
import { FaHome } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import classes from './NavbarMinimal.module.css';

interface NavbarLinkProps {
    icon: typeof IconHome2;
    label: string;
    active?: boolean;
    onClick?(): void;
}

function NavbarLink({ icon: Icon, label, active, onClick }: NavbarLinkProps) {

    return (
        <div onClick={onClick} style={{ cursor: 'pointer', width: '100%', height: 50, margin: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ width: '35%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }} >
                <UnstyledButton onClick={onClick} className={classes.link} data-active={active || undefined}>
                     {
                        
                        label === 'Nos occasions' ? <IoCarSport size={20} /> : label === 'Nos services' ? <FaHandHoldingDollar size={20} /> :  label === 'Accueil' ? <FaHome size={20} /> :  label === 'Qui nous sommes' ? <FaUsers size={20} /> : label === 'Contact' ? <MdEmail size={20} /> :
                        <Icon style={{ width: rem(20), height: rem(20) }} stroke={1.5} /> 
                     }

                </UnstyledButton>
            </div>
            <div style={{ width: '65%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: "center" }} >
                <p>{label}</p>
            </div>
        </div>

    );
}

const mockdata = [
    { icon: IconHome2, label: 'Accueil', path: '/' },
    { icon: IconHome2, label: 'Nos occasions', path: '/occasions' },
    { icon: IconDeviceDesktopAnalytics, label: 'Nos services', path: '/services' },
    { icon: IconUser, label: 'Qui nous sommes', path: '/apropos' },
    { icon: IconMail, label: 'Contact', path: '/contact' },
];

export function NavbarMinimal() {
    const [active, setActive] = useState(2);
    let location = useLocation();

    useEffect(() => {
        mockdata.filter((x, i) => {
            if (x.path === location.pathname) {
                return setActive(i)
            }
        })
    }, [location])

    let navigate = useNavigate();
    const routeChange = (path: string) => {
        navigate(path);
    }

    const links = mockdata.map((link, index) => (
        <NavbarLink
            {...link}
            key={link.label}
            active={index === active}
            onClick={() => {
                setActive(index)
                routeChange(link.path)
            }
            }
        />
    ));

    return (
        <nav className={classes.navbar}>
            <div className={classes.navbarMain}>
                <Stack justify="center" gap={0}>
                    {links}
                </Stack>
            </div>
        </nav>
    );
}