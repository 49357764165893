import { Text, Container, ActionIcon, Group, rem } from '@mantine/core';
import { IconBrandTwitter, IconBrandFacebook, IconBrandInstagram } from '@tabler/icons-react';
import logo from "../assets/logo_makki_light.png"
import classes from './FooterSocial.module.css';
import { useState, useEffect } from 'react';

const data = [
  {
    title: 'A propos',
    links: [
      { label: 'Service', link: '#' },
      { label: 'Prix', link: '#' },
      { label: 'Contact', link: '#' },
      { label: 'Réseaux', link: '#' },
    ],
  },
  {
    title: 'Service',
    links: [
      { label: 'Carte grise', link: '#' },
      { label: 'Financement', link: '#' },
      { label: 'Conseil', link: '#' },
      { label: 'Recherche', link: '#' },
    ],
  },

];

export function FooterSocial() {
  const [width, setWidth] = useState<number>(window.innerWidth);

function handleWindowSizeChange() {
    setWidth(window.innerWidth);
}
useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);

const isMobile = width <= 768;

  const groups = data.map((group) => {
    const links = group.links.map((link, index) => (
      <Text<'a'>
        key={index}
        className={classes.link}
        component="a"
        href={link.link}
        onClick={(event) => event.preventDefault()}
      >
        {link.label}
      </Text>
    ));

    return (
      <div className={classes.wrapper} key={group.title}>
        <Text className={classes.title}>{group.title}</Text>
        {links}
      </div>
    );
  });

  return (
    <footer className={classes.footer}>
      <Container className={isMobile ? classes.innerMobile : classes.inner}>
        <div className={isMobile ? classes.logoMobile : classes.logo}>
        <img width={300} src={logo} />
          <Text size="xs"   className={isMobile ? classes.descriptionMobile : classes.description}>
          </Text>
        </div>
        <div  className={isMobile ? classes.groupsMobile : classes.groups}>{groups}</div>
      </Container>
      <Container className={isMobile ? classes.afterFooterMobile : classes.afterFooter}>
        <Text style={{color:'#2c2b29'}} size="sm">
          © 2024 - Carmatch riviera. All rights reserved.
        </Text>

        <Group gap={0} className={isMobile ? classes.socialMobile : classes.social} justify="flex-end" wrap="nowrap">
          <ActionIcon component='a' href={"https://www.facebook.com/groups/2248338119/user/61556256103057/?locale=fr_FR"} size="lg" color="black" variant="subtle">
            <IconBrandFacebook style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
          </ActionIcon>
          <ActionIcon component='a' href={"https://www.instagram.com/carmatch_riviera/"}  size="lg" color="black" variant="subtle">
            <IconBrandInstagram  style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
          </ActionIcon>
        </Group>
      </Container>
    </footer>
  );
}