import React, { useState, useEffect } from 'react';
import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
} from '@mantine/core';
import classes from './AuthenticationTitle.module.css';
import { TableSelection } from './AdminCars';
import { CarForm } from './CarForm';
import dodge from "../assets/Dodge.jpg";
import golf from "../assets/golf.jpg";
import cupra from "../assets/cupra.jpg";
import mercedes from "../assets/mercedes.jpg";
import axios from 'axios';

export function AuthenticationTitle() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [userConnected, setUserConnected] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showConfModal, setShowConfModal] = useState(false);
  const [carToUpdate, setCarToUpdate] = useState(null);
  const [carToDelete, setCarToDelete] = useState(null);
  const [mode, setMode] = useState('add');
  const [carToEdit, setCarToEdit] = useState(null);
  const [dataCarToUpdate, setDataCarToUpdate] = useState<any>([]);

  const [cars, setCars] = useState<any>([]);

  //https://api.carmatchriviera.fr
//http://127.0.0.1:8000/

  useEffect(() => {
      const fetchData = async () => {
          try {
              const response = await axios.get('https://api.carmatchriviera.fr/get_cars');
              setCars(response.data);
          } catch (error) {
              console.error('Error fetching data:', error);
          }
      };

      fetchData();
  }, [showModal, showConfModal]);


  useEffect(() => {
    if (carToUpdate) {
      let key = (carToUpdate ?? 0) - 1;
      console.log(key)
      setShowModal(true);
      const array = cars[key];
      setDataCarToUpdate(array)
    }
  }, [carToUpdate])

  // Check if user is already logged in
  useEffect(() => {
    const storedUser = localStorage.getItem('userConnected');
    if (storedUser) {
      const { timestamp } = JSON.parse(storedUser);
      if (Date.now() - timestamp < 24 * 60 * 60 * 1000) {
        setUserConnected(true);
      }
    }
  }, []);

  // Function to handle login
  const handleLogin = () => {
    // Check if email and password match
    if (email === 'contact@carmatchriviera.fr' && password === '123456789Makki!') {
      // Store user info in local storage
      const userInfo = { email, password, timestamp: Date.now() };
      localStorage.setItem('userConnected', JSON.stringify(userInfo));
      setUserConnected(true);
      setErrorMessage('');
    } else {
      setErrorMessage('Identifiant ou mot de passe incorrect');
    }
  };

  // Function to handle logout
  const handleLogout = () => {
    localStorage.removeItem('userConnected');
    setUserConnected(false);
  };

  // Function to handle logout
  const handleAdd = () => {
    setShowModal(true)
    setDataCarToUpdate(false);

  };

  const handleDelete = async (id:any) => {

    //https://api.carmatchriviera.fr
//http://127.0.0.1:8000/

    try {
        axios.delete(`https://api.carmatchriviera.fr/api/cars/${id}`, {
            headers: {
              'accept': '*/*'
            }
          }).then(r=>setShowConfModal(false))
    } catch (error) {
      console.error('Error delete cars:', error);
    }

  };



  if (userConnected) {
    if (showModal) {
      return (
        <Container size={'lg'} py={180}>
          <CarForm setCarToUpdate={setCarToUpdate} setShowModal={setShowModal} data={carToUpdate} mode={mode} setMode={setMode} carToEdit={carToEdit} />
        </Container>
      )
    } else {
      return (
        <Container size={'lg'} py={180}>
          <div style={{
            justifyContent: 'flex-end', display: 'flex'
            , width: '100%', padding: 5
          }}>
            { showConfModal &&
              <div style={{ width: "100vw", height: '100vh', position: 'absolute', top: 0,right:0, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 10, zIndex: 9999, backgroundColor: '#92929223' }}>
                <div style={{ width: 250, height: 200, backgroundColor: '#3c3b3b', borderRadius: 10, display: 'flex', justifyContent: 'space-evenly', alignContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 10 }}>
                  <p>Confirmer la suppression ?</p>
                  <Button onClick={()=>handleDelete(carToDelete)} style={{ backgroundColor: '#22a81e' }}> Oui </Button>
                  <Button onClick={()=>{setCarToDelete(null);setShowConfModal(false)}} style={{ backgroundColor: '#616161', }}> Annuler la suppression </Button>
                </div>
              </div>
            }

            <Button style={{ backgroundColor: '#ba0c2f' }} onClick={handleLogout}>Deconnexion</Button>
          </div>

          <TableSelection setShowConfModal={setShowConfModal} setCarToDelete={setCarToDelete} setCarToUpdate={setCarToUpdate} data={cars} setMode={setMode} setCarToEdit={setCarToEdit} />
          <div style={{
            justifyContent: 'flex-end', display: 'flex'
            , width: '100%', padding: 5
          }}>
            <Button style={{ backgroundColor: '#268406' }} onClick={handleAdd}>+ Ajouter une voiture</Button>
          </div>

        </Container>
      );
    }

  } else {
    return (
      <Container size={420} py={180}>
        <Title ta="center" className={classes.title}>
          Salut Makki, bienvenue
        </Title>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <TextInput
            label="Email"
            placeholder="Email"
            required
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <PasswordInput
            label="Password"
            placeholder="Mot de passe"
            required
            mt="md"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          {errorMessage && <Text color="red">{errorMessage}</Text>}
          <Button style={{ backgroundColor: '#ba0c2f' }} fullWidth mt="xl" onClick={handleLogin}>
            Se connecter
          </Button>
        </Paper>
      </Container>
    );
  }
}
