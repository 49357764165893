import { Button, Menu, Group, ActionIcon, rem, useMantineTheme, Container } from '@mantine/core';
import { IconTrash, IconBookmark, IconCalendar, IconChevronDown } from '@tabler/icons-react';
import classes from './SplitButton.module.css';
import { useState } from 'react';
import axios from 'axios';


export function SplitButton({setShowConfModal, setCarToDelete, setCarToUpdate, item, setMode, setCarToEdit}: any) {
    const theme = useMantineTheme();


    const handleEdit = (item: any) => {
        setCarToUpdate(item.id);
        setCarToEdit(item);
        setMode('edit');
    }

    //http://127.0.0.1:8000/api/cars/1'

    const handleSubmit = async (id:any) => {

        //https://api.carmatchriviera.fr
    //http://127.0.0.1:8000/
    
        try {
            axios.delete(`https://api.carmatchriviera.fr/api/cars/${id}`, {
                headers: {
                  'accept': '*/*'
                }
              })
        } catch (error) {
          console.error('Error delete cars:', error);
        }
    
      };

    const handleSuppress = (id: string, multiple: boolean) => {
        setCarToDelete(id);
        setShowConfModal(true);
    }



    return (<>
        <Group wrap="nowrap" gap={0}>
            <Button style={{ backgroundColor: '#3459e1' }} className={classes.button}>Options</Button>
            <Menu transitionProps={{ transition: 'pop' }} position="bottom-end" withinPortal>
                <Menu.Target>
                    <ActionIcon
                        variant="filled"
                        color={theme.primaryColor}
                        size={36}
                        className={classes.menuControl}
                        style={{ backgroundColor: '#3459e1' }}
                    >
                        <IconChevronDown style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                    </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                    <Menu.Item
                        onClick={() => handleEdit(item)}
                        style={{ backgroundColor: '#db6e26', marginBottom:5 }}
                    >
                        Modifié
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => handleSuppress(item.id, false)}
                        style={{ backgroundColor: '#cc4c4c', marginBottom:5 }}
                    >
                        Supprimer
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
        </Group>
    </>

    );
}