import '@mantine/core/styles.css';
import CarSaleSection from "./CarSaleSection";
import { FooterSocial } from './Footer';
import { GetInTouch } from './Contact';
import { Services } from './Services';
import Search from './Search';
import { HeroImageRight } from './HeroImage';
import YourComponent from './UploadCar';

const Home = () => {
    return (
        <>
            <HeroImageRight />
            {/* <YourComponent /> */}
        </>
    );
}
export default Home;